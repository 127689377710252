import React, { useContext, useState, useEffect } from "react";
import "../../../styles/index.css";
import "../banks.css";
import AppContext from "../../../context/app-context.ts";
import { TBankNavs } from "../banks.tsx";
import { IoMdArrowBack } from "react-icons/io";
import { IJobs, TSectorTypes } from "../../../context/interface.ts";
import { bankAccountUniqueUrlBase, copiedToClipboard } from "../../../utils/index.ts";
import APopupModal from "../../../components/a-typical-popup-container/a-typical-popup-container.tsx";
import { shortenText } from "../../../utils/index.ts";
import { MDBDropdown, MDBDropdownMenu, MDBDropdownToggle, MDBDropdownItem } from "mdb-react-ui-kit";
import { MdContentCopy } from "react-icons/md";
import { IoOpenOutline } from "react-icons/io5";
import { TbWorldWww } from "react-icons/tb";

type Props = {
  setScreen: React.Dispatch<React.SetStateAction<TBankNavs>>;
  display: boolean;
  screen: TBankNavs;
};

export const JobDetails = function ({ display, setScreen, screen }: Props) {
  const [jobsData, setJobsData] = useState<IJobs>({} as IJobs);
  const [activeSubJobsName, setActiveSubJobName] = useState("");
  const [activeJob, setActiveJob] = useState<IJobs | undefined>({} as IJobs);

  const {
    backendServer,
    openNotification,
    setIsLoading,
    usersData,
    changeScreen,
    updateUsersData,
    getBasedOnSectorReferenceSmart,
  } = useContext(AppContext);

  useEffect(() => {
    getJobDetails();
  }, [screen?.data, screen?.screen]);

  async function getJobDetails() {
    if (!screen?.data || screen?.screen !== "job-details") return;
    try {
      setIsLoading(true);

      const returned = await (
        await fetch(`${backendServer}/jobs/get?id=${screen?.data}`, {
          method: "GET",
          headers: {
            token: localStorage.getItem("ddsatoken") || "",
          },
        })
      ).json();

      if (returned.status === "Internal server error") throw new Error(returned.message);

      setJobsData(returned?.data);

      if (!returned?.data?.child_jobs?.length) setActiveJob(returned?.data);
      else {
        setActiveJob(returned?.data?.child_jobs[0]);
        setActiveSubJobName(returned?.data?.child_jobs[0]?.job_name);
      }

      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      openNotification(
        "We are unable to fetch the details for ths job at the moment. Try again in a few minutes."
      );
    }
  }

  async function subscribeforjob() {
    try {
      setIsLoading(true);

      const returned = await (
        await fetch(`${backendServer}/jobs/subscribe/${usersData?._id}/${activeJob?._id}`, {
          method: "PUT",
          headers: {
            token: localStorage.getItem("ddsatoken") || "",
          },
        })
      ).json();

      if (returned.status === "Internal server error") throw new Error(returned.message);

      openNotification(
        `You have successfully subscribed for ${activeJob?.job_name}`,
        "Subscription Successful!"
      );
      setIsLoading(false);
      updateUsersData();
      changeScreen("myjobs");
    } catch (err: any) {
      setIsLoading(false);
      openNotification(
        err?.message ||
          "This job is not currently available. It's not you, it's us. Try again after some time."
      );
    }
  }

  if (!display) return <></>;

  return (
    <div className="screen-container job-list-container">
      <p
        className="small cursor fix-back-button"
        onClick={() => {
          setScreen({
            screen: "bank-jobs",
            data: screen?.data,
          });
          setJobsData({} as IJobs);
          setActiveJob({} as IJobs);
          setActiveSubJobName("");
        }}
      >
        <IoMdArrowBack style={{ marginTop: -3 }} size={17} /> Back
      </p>

      <div className="flex-row job-details-one">
        <h3 className="vbig removemargin">{jobsData?.job_name}</h3>

        {jobsData?.child_jobs?.length ? (
          <MDBDropdown
            style={{ borderRadius: "5px", width: "30%" }}
            id="mdb-dropdown-custom-dropdown"
            className="mdbdropdown-banks"
          >
            <MDBDropdownToggle id="mdb-dropdown-custom-in-dropdown" color="light">
              {activeSubJobsName || "Pick a Job"}
            </MDBDropdownToggle>

            <MDBDropdownMenu style={{ width: "100%", maxHeight: 300, overflow: "auto" }}>
              {jobsData?.child_jobs?.map((e, i: number) => (
                <MDBDropdownItem
                  link
                  key={i}
                  href="#banks"
                  onClick={() => {
                    setActiveSubJobName(e?.job_name);
                    setActiveJob(e);
                  }}
                >
                  {e?.job_name}
                </MDBDropdownItem>
              ))}
            </MDBDropdownMenu>
          </MDBDropdown>
        ) : (
          ""
        )}

        {jobsData!.for_sector === "banks" &&
          jobsData!.job_category === "Accounts" &&
          getBasedOnSectorReferenceSmart(jobsData?.for_sector as TSectorTypes, jobsData?.based_on)
            ?.data?.is_active &&
          getBasedOnSectorReferenceSmart(jobsData?.for_sector as TSectorTypes, jobsData?.based_on)
            ?.data?.can_use_externals && (
            <div className="job-details-card-container jbd-extra-job">
              <p className="biggerText">
                <TbWorldWww size={30} color="#EC3B35" />
                &nbsp; Your Unique URL
              </p>
              <p className="small">
                Want to open an account? Click to complete and open an account online or copy the
                link to share.
              </p>
              <div className="flex-row no-wrap">
                <button
                  onClick={async () =>
                    await copiedToClipboard(
                      `${bankAccountUniqueUrlBase}/${
                        getBasedOnSectorReferenceSmart(
                          jobsData?.for_sector as TSectorTypes,
                          jobsData?.based_on
                        )?.data?._id
                      }/${usersData?._id}`
                    )
                  }
                  className="biggerText alignTextCenter cursor removemargin themeBtnBlank"
                >
                  <MdContentCopy />
                  <br></br> Copy
                </button>

                <button
                  onClick={() => {
                    window.open(
                      `${bankAccountUniqueUrlBase}/${
                        getBasedOnSectorReferenceSmart(
                          jobsData?.for_sector as TSectorTypes,
                          jobsData?.based_on
                        )?.data?._id
                      }/${usersData?._id}`,
                      "_blank"
                    );
                  }}
                  className="biggerText alignTextCenter cursor removemargin themeBtnBlank"
                >
                  <IoOpenOutline />
                  <br></br> Open Link
                </button>
              </div>
            </div>
          )}
      </div>

      <div className="flex-row job-details-two">
        <div className="job-details-card-container jbd-desription">
          <div className="normal-flex-row space-between">
            <p className="biggerText">
              <img src="/images/icons/description.png" alt="DDSA Jobs" />
              &nbsp; Description
            </p>
            <p className="biggerText">
              ₦{activeJob?.job_incentive}
              <span className="small"> /job</span>
            </p>
          </div>
          <p className="small">{activeJob?.job_description}</p>
        </div>
        <div className="job-details-card-container jbd-category">
          <p className="biggerText">
            <img src="/images/icons/category.png" alt="DDSA Jobs" />
            &nbsp; Category
          </p>
          <p className="small">{activeJob?.job_category}</p>
        </div>
      </div>

      <div className="flex-row job-details-three">
        <div className="job-details-card-container jbd-main-assets-cont">
          <p className="biggerText">
            <img src="/images/icons/prerequisites.png" alt="DDSA Jobs" />
            &nbsp; Prerequisites
          </p>

          <div>
            {activeJob?.prerequisites?.length ? (
              activeJob?.prerequisites?.map((e, i: number) => (
                <APopupModal
                  key={i}
                  button={
                    <div className="normal-flex-row small-gap space-between assets-card">
                      <p className="small removemargin">
                        {shortenText({ text: e?.name, maxLen: 20 }).text}
                        <br></br>
                        <span className="greyText">
                          {shortenText({ text: e?.description, maxLen: 20 }).text}
                        </span>
                      </p>
                      <p className="small removemargin">
                        <span className="greyText">{e?.is_required ? "Required" : ""}</span>
                        <br></br>
                        <span> </span>
                      </p>
                    </div>
                  }
                  content={
                    <div className="popup-common-filter-dropdown jbd-popup-dropdown">
                      <div className="normal-flex-column small-gap">
                        <p className="small removemargin boldText">
                          {e?.name} -{" "}
                          <span className="greyText">
                            {e?.is_required ? "Required" : "Not Required"}
                          </span>
                        </p>
                        <p className="small removemargin">{e?.description}</p>
                      </div>
                    </div>
                  }
                  trigger="click"
                  showArrow={true}
                  buttonStyles={{ width: "100%" }}
                />
              ))
            ) : (
              <div className="flex-row">
                <p className="small greyText alignTextCenter">Not available</p>
              </div>
            )}
          </div>
        </div>

        <div className="job-details-card-container jbd-main-assets-cont">
          <p className="biggerText">
            <img src="/images/icons/proofs.png" alt="DDSA Jobs" />
            &nbsp; Proofs
          </p>

          <div>
            {activeJob?.proofs_needed?.length ? (
              activeJob?.proofs_needed?.map((e, i: number) => (
                <APopupModal
                  key={i}
                  button={
                    <div className="normal-flex-row small-gap space-between assets-card">
                      <p className="small removemargin">
                        {shortenText({ text: e?.proof_name, maxLen: 20 }).text}
                        <br></br>
                        <span className="greyText">{""}</span>
                      </p>
                      <p className="small removemargin">
                        <span className="greyText">{e?.is_required ? "Required" : ""}</span>
                        <br></br>
                        <span>type: {e?.proof_type}</span>
                      </p>
                    </div>
                  }
                  trigger="click"
                  content={
                    <div className="popup-common-filter-dropdown jbd-popup-dropdown">
                      <div className="normal-flex-column small-gap">
                        <p className="small removemargin boldText">
                          {e?.proof_name} -{" "}
                          <span className="greyText">
                            {e?.is_required ? "Required" : "Not Required"}
                          </span>
                        </p>
                        <p className="small removemargin">
                          {" "}
                          {e?.proof_type !== "empty" && e?.proof_type !== "other" ? (
                            <span>
                              We expect submissions of "{e?.proof_type}" for this job upon
                              completion.
                            </span>
                          ) : (
                            <span>No submission is required.</span>
                          )}
                        </p>
                      </div>
                    </div>
                  }
                  showArrow={true}
                  buttonStyles={{ width: "100%" }}
                />
              ))
            ) : (
              <div className="flex-row">
                <p className="small greyText alignTextCenter">Not available</p>
              </div>
            )}
          </div>
        </div>

        <div
          onClick={() => {
            if (activeJob?.job_resources?.length) {
              setScreen({
                screen: "resources",
                data: {
                  parent_job: jobsData?._id,
                  child_job: activeJob?._id,
                },
              });
            }
          }}
          className="job-details-card-container jbd-main-assets-cont"
        >
          <p className="biggerText">
            <img src="/images/icons/resources.png" alt="DDSA Jobs" />
            &nbsp; Resources
          </p>

          <div>
            {activeJob?.job_resources?.length ? (
              activeJob?.job_resources?.map((e, i: number) => (
                <div key={i} className="normal-flex-row small-gap space-between assets-card">
                  <p className="small removemargin">
                    {shortenText({ text: e?.text, maxLen: 25 }).text}
                    <br></br>
                    <span>{shortenText({ text: e?.description, maxLen: 20 }).text}</span>
                  </p>
                  <p className="small removemargin greyText">
                    <br></br>
                    <span>{e?.files?.length} file(s)</span>
                  </p>
                </div>
              ))
            ) : (
              <div className="flex-row">
                <p className="small greyText alignTextCenter">Not available</p>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="flex-row jbd-subscribeforjob-container">
        {!usersData?.jobs?.find((e) => e?.job_id?._id === activeJob?._id) && (
          <div className="job-details-card-container jbd-subscribeforjob">
            <p className="big blackText">Subscribe to this Job</p>
            <button className="themeBtn" onClick={subscribeforjob}>
              Proceed
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

import React, { useContext, useState, useEffect } from "react";
import "../../../styles/index.css";
import "../my-jobs.css";
import AppContext from "../../../context/app-context.ts";
import { IoMdArrowBack } from "react-icons/io";
import { TACompletedJob, TAJob, TAJobProof } from "../../../context/interface.ts";
import { TMyJobNavs } from "../my-jobs.tsx";
import { ProofsCollectionTable } from "../../../components/proofs-collection-table/proofs-collection-table.tsx";
import {
  MDBInput,
  MDBDropdown,
  MDBDropdownItem,
  MDBDropdownMenu,
  MDBDropdownToggle,
} from "mdb-react-ui-kit";
import { fileToBase64 } from "../../../utils/index.ts";
import { popup } from "../../../vanilla-functions/model.js";

type Props = {
  setScreen: React.Dispatch<React.SetStateAction<TMyJobNavs>>;
  display: boolean;
  screen: TMyJobNavs;
};

export type TStateAproof = Pick<TAJobProof, "proof_name" | "comment"> & {
  value: any;
  proof_type: "file" | "text" | "other" | "empty" | "form-data" | "";
};

export const SubmitClaim = function ({ display, setScreen, screen }: Props) {
  const [page, setPage] = useState(0);
  const [completedJobData, setCompletedJobData] = useState<TACompletedJob[]>([]);
  const [jobData, setJobData] = useState<TAJob>({} as TAJob);

  const [totalClaimedDone, setTotalClaimedDone] = useState<number | string>();
  const [proofsUploaded, setProofsUploaded] = useState<TStateAproof[]>([]);
  const [scafoldingDone, setScafoldingDone] = useState(false);

  const [completedJobIdFromScafold, setCompletedJobIdFromScafold] = useState("");

  const [aProof, setAProof] = useState<TStateAproof>({
    proof_name: "",
    proof_type: "",
    value: "",
    comment: "",
  });

  const { openNotification, setIsLoading, usersData, updateUsersData, backendServer } =
    useContext(AppContext);

  useEffect(() => {
    getCompletedJobs();
  }, [screen?.data, screen?.screen]);

  async function getCompletedJobs() {
    if (!screen?.data || screen?.screen !== "submit-claim") return;
    try {
      setIsLoading(true);

      const foundJob = usersData?.jobs?.find((e) => e?._id === screen?.data);

      setJobData(foundJob as TAJob);
      setCompletedJobData(foundJob!.completed);

      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      openNotification("Failed to load page, please try again later.");
    }
  }

  async function uploadProof() {
    try {
      if (!totalClaimedDone) return popup("Please set the number of jobs done");
      if (!aProof?.proof_type) return popup("We need the type of proof");
      if (!aProof?.proof_name) return popup("Please set a proof title to proceed");

      if (aProof?.proof_type === "file" && !aProof?.value)
        return popup("You'll need to attach a file for a file proof type");

      let completedJobId;

      setIsLoading(true);
      if (!scafoldingDone) {
        //upload scafold
        const returned = await (
          await fetch(
            `${backendServer}/jobs/proof/submit/${usersData?._id}/${jobData?.job_id?._id}/scafolding`,
            {
              credentials: "include",
              method: "PUT",
              headers: {
                "content-type": "application/json",
                token: localStorage.getItem("ddsatoken") || "",
              },
              body: JSON.stringify({
                total_number_claimed_done: totalClaimedDone,
              }),
            }
          )
        ).json();

        if (returned.status === "Internal server error") throw new Error(returned.message);
        setScafoldingDone(true);
        setCompletedJobIdFromScafold(returned?.data?.completedJobId);
        completedJobId = returned?.data?.completedJobId;
      }

      //upload proof normal or proof file

      let url: string = "";

      if (aProof?.proof_type === "file" && aProof?.value)
        url = `${backendServer}/jobs/proof/submit/${usersData?._id}/${
          jobData?.job_id?._id
        }/proofs-file?completed_jobid=${completedJobId || completedJobIdFromScafold}`;
      else
        url = `${backendServer}/jobs/proof/submit/${usersData?._id}/${
          jobData?.job_id?._id
        }/proofs-normal?completed_jobid=${completedJobId || completedJobIdFromScafold}`;

      if (aProof?.proof_type === "file" && aProof?.value) {
        const formData = new FormData();

        formData?.append("proof_name", aProof?.proof_name);
        formData?.append("proof_type", aProof?.proof_type);
        formData?.append("file", aProof?.value);

        if (aProof?.comment) formData?.append("comment", aProof?.comment);

        const returned = await (
          await fetch(url, {
            method: "PUT",
            body: formData,
            headers: {
              enctype: "multipart/form-data",
              token: localStorage.getItem("ddsatoken") || "",
            },
          })
        ).json();

        if (returned.status === "Internal server error") throw new Error(returned.message);
      } else {
        const returned = await (
          await fetch(url, {
            credentials: "include",
            method: "PUT",
            headers: {
              "content-type": "application/json",
              token: localStorage.getItem("ddsatoken") || "",
            },
            body: JSON.stringify({
              proof_name: aProof?.proof_name,
              proof_type: aProof?.proof_type,
              comment: aProof?.comment,
            }),
          })
        ).json();

        if (returned.status === "Internal server error") throw new Error(returned.message);
      }

      //reset state
      setProofsUploaded((p) => [...p, aProof]);
      setAProof({
        proof_name: "",
        proof_type: "",
        value: "",
        comment: "",
      });

      setIsLoading(false);
      updateUsersData();
      openNotification("Proof Added");
    } catch (err) {
      console.log(err);
      setIsLoading(false);
      openNotification("We are unable to fetch the your jobs right now. Please try again.");
    }
  }

  if (!display) return <></>;

  return (
    <div className="screen-container job-list-container">
      <p
        className="small cursor fix-back-button"
        onClick={() => {
          setScreen({
            screen: "claims-table",
            data: screen?.data,
          });
          setCompletedJobData([]);
          setTotalClaimedDone("");
          setProofsUploaded([]);
          setAProof({
            proof_name: "",
            proof_type: "",
            value: "",
            comment: "",
          });
          setScafoldingDone(false);
        }}
      >
        <IoMdArrowBack style={{ marginTop: -3 }} size={17} /> Back
      </p>

      <h3 className="vbig-2 removemargin blackText">Submit Claim</h3>
      <p className="small removemargin">
        Please upload the proof of this completed job for verification.
      </p>

      <div className="myjobs-table-container">
        <div className="table-utilities jobs-table-utilities">
          <div className="table-utilities-extra">
            <p className="small boldText removemargin">Proofs Uploaded (Max 6)</p>
          </div>
          <div className="table-utilities-icons"></div>
        </div>
        <ProofsCollectionTable
          page={page}
          setPage={setPage}
          data={proofsUploaded}
          setScreen={setScreen}
        />
      </div>

      <div className="flex-row space-between sc-inputs-group">
        <MDBInput
          title="Total Claimed Done"
          label="Total Claimed Done"
          type="number"
          wrapperClass="mdbIn mdb-sc-input"
          onChange={(e) => setTotalClaimedDone(Number(e?.target?.value))}
          labelClass="mdb-dropdown-custom-in"
          value={totalClaimedDone}
          onClick={(e) => e.stopPropagation()}
          wrapperStyle={{ width: "100%" }}
          min={1}
          disabled={scafoldingDone}
        />
      </div>

      {proofsUploaded?.length <= 5 && (
        <div className="flex-row space-between sc-files-group">
          <div
            onClick={() => {
              if (aProof?.proof_type !== "file")
                popup("Please select proof type file to attach a file");
            }}
            className={`myjob-details-card-container sc-attach-file-container ${
              aProof?.proof_type !== "file" && "sc-attach-file-container-disabled"
            }`}
          >
            <span>
              {aProof?.value && <img src="/images/file-placeholder.png" alt="DDSA File" />}
            </span>
            <hr></hr>
            <div>
              <img src="/images/icons/sc-upload-icon.png" alt="DDSA Claims" />
              <p className="small blackText">
                Select Files<br></br>
                <span className="greyText">
                  JPG, JPEG. DOC, DOCX. XLSX, PNG, PDF. Max size no more than 7MB each.
                </span>
              </p>

              <img src="/images/icons/sc-file-button-icon.png" alt="DDSA Claims" />
              <input
                disabled={aProof?.proof_type !== "file" && true}
                type="file"
                accept=".jpeg,.png,.jpg,.doc,.docx,.pdf"
                max={7}
                onChange={async ({ target: { files } }) => {
                  if (files !== null) {
                    try {
                      const file = files[0];
                      setAProof((p) => ({ ...p, value: file }));
                      const imgSrc = await fileToBase64(file);
                    } catch (error) {
                      // console.log(error)
                    }
                  }
                }}
              />
            </div>
          </div>

          <div className="normal-flex-column sc-attach-text-container">
            <MDBDropdown
              style={{ borderRadius: "5px", width: "100%" }}
              id="mdb-dropdown-custom-dropdown"
            >
              <MDBDropdownToggle id="mdb-dropdown-custom-in-dropdown" color="light">
                {aProof?.proof_type || "Select a Proof Type"}
              </MDBDropdownToggle>

              <MDBDropdownMenu style={{ width: "100%", maxHeight: 300, overflow: "auto" }}>
                <MDBDropdownItem
                  link
                  href="#myjobs"
                  onClick={() => setAProof((p) => ({ ...p, proof_type: "file" }))}
                >
                  File
                </MDBDropdownItem>
                <MDBDropdownItem
                  link
                  href="#myjobs"
                  onClick={() => setAProof((p) => ({ ...p, proof_type: "text" }))}
                >
                  Text
                </MDBDropdownItem>
                <MDBDropdownItem
                  link
                  href="#myjobs"
                  onClick={() => setAProof((p) => ({ ...p, proof_type: "other" }))}
                >
                  Others
                </MDBDropdownItem>
              </MDBDropdownMenu>
            </MDBDropdown>

            <MDBInput
              title="Proof Title"
              label="Proof Title"
              type="text"
              wrapperClass="mdbIn mdb-sc-input"
              onChange={(e) => setAProof((p) => ({ ...p, proof_name: e?.target?.value }))}
              labelClass="mdb-dropdown-custom-in"
              value={aProof?.proof_name}
              onClick={(e) => e.stopPropagation()}
              wrapperStyle={{ width: "100%" }}
              maxLength={200}
            />

            <p className="small greyText boldText removemargin">Comment</p>

            <textarea
              onChange={(e) => setAProof((p) => ({ ...p, comment: e?.target?.value }))}
              value={aProof?.comment}
              maxLength={500}
              className="small"
            />

            <button
              onClick={uploadProof}
              className="themeBtn sc-btn-submit"
              style={{ background: "#10d050ae" }}
            >
              Add
            </button>

            <p className="small greyText">Note: You can add multiple proofs under a claim</p>
          </div>
        </div>
      )}
    </div>
  );
};
